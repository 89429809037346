<template>
  <v-content
    id="pages"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'"
  >
    <v-img
      v-if="getBackImages"
      class="mybackimage"
      :class="{
        'v-image--sm': this.$vuetify.breakpoint.smAndDown,
        'v-image--md': this.$vuetify.breakpoint.mdAndDown,
        'v-image--lg': this.$vuetify.breakpoint.lgAndDown,
        'v-image--xl': this.$vuetify.breakpoint.xlAndDown,
      }"
      :src="src"
      gradient="to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)"
      min-height="100%"
    >
      <v-responsive
        :style="styles"
        min-height="100vh"
        class="d-flex align-center"
      >
        <router-view />
      </v-responsive>
    </v-img>
  </v-content>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: 'PagesCoreView',

    data: () => ({

    }),

    computed: {
      ...mapGetters(['getBackImages']),

      srcs() {
        if (!this.getBackImages) {
          return {
            '/pages/lock': '',
            '/pages/login': '',
            '/pages/pricing': '',
            '/pages/register': ''
          }
        }
        const limit = this.getBackImages.photos.length
        return {
          '/pages/lock': this.getBackImages.photos[Math.floor(Math.random() * limit)].src.landscape,
          '/pages/login': this.getBackImages.photos[Math.floor(Math.random() * limit)].src.landscape,
          '/pages/pricing': this.getBackImages.photos[Math.floor(Math.random() * limit)].src.landscape,
          '/pages/register': this.getBackImages.photos[Math.floor(Math.random() * limit)].src.landscape
        }
      },

      src () {
        return this.srcs[this.$route.path]
      },

      styles () {
        const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100
        const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 175 : 150
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
          opacity: 0.75,
        }
      },

    },
    mounted() {
      this.$store.dispatch('getBackgroundImages')
    },
    created() {

    }
  }
</script>

<style scoped>
.mybackimage {

}
</style>
